// return a json object starting from string semicolon separated values
// EG: "x=true;y=1;z='lorem'" => {"x":true, "y":1, "z": "lorem"}

export let fromStringToJson = function (str) {
  let json = {};
  if (str) {
    str.split(';').forEach(function (e, i) {
      let opt = e.split(':').map(function (el) {
        return el.trim();
      });
      if (opt[0]) json[opt[0]] = parseValue(opt[1]);
    });
    return json
  }
};

function parseValue(str) {
  if ('true' === str) return true;
  else if ('false' === str) return false;
  else if (!isNaN(str * 1)) return parseFloat(str);
  return str;
}