// Avoid `console` errors in browsers that lack a console.
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import $ from 'jquery';
import {fromStringToJson} from "./json_ultils";

gsap.registerPlugin(ScrollTrigger);


// Place any jQuery/helper plugins in here.
//let tl = gsap.timeline({defaults: {duration: 1}});

//tl.to('.square1', {x: 400}).to('.square2', {x: 500}, '-=.5')
/*
gsap.to(".square2", {
  scrollTrigger: {
    markers: true,
   trigger: '.square2',
    start: 'top center',
    end: 'top 100px',
    scrub: true,
    pin: true,
  } ,
  duration: 6,
  x: 800, rotate:360
});



gsap.utils.toArray("section").forEach((section, i) => {
  ScrollTrigger.create({
    trigger: section,
    start: "top top",
    pin: true,
    pinSpacing: false
  });
});
ScrollTrigger.create({
  snap: 1 / 4 // snap whole page to the closest section!
});
 */


let sections = gsap.utils.toArray("section");

sections.forEach(section => {
  let canvas = section.querySelector("canvas");
  canvas ? initCanvas(section, canvas) : initOther(section);
});

function initCanvas(section, canvas) {

  let text = section.querySelector(".text");
  let context = canvas.getContext("2d");
  canvas.width = 1158;
  canvas.height = 770;

  // per preload
  let imgCounter = 0;

  let frameCount = 779;
  const currentFrame = index => (
    `img/test2/webp/${(index + 1).toString().padStart(5, '0')}.jpg.webp`
  );

  let images = []
  let airpods = {
    frame: 0
  };


  for (let i = 0; i < frameCount; i++) {
    let img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  preloadFrames();

  gsap.timeline({
    onUpdate: render,
    scrollTrigger: {
      //  trigger: section,
      //  pin: true,
      //  scrub: 0.5,
      //end: "+=9000%",
      // end: '+=' + frameCount * 50,
      // markers: false,
      // preventOverlaps: true,


      scrub: 0.5,
      trigger: section,
      pin: true,
      start: 'top top',
      end: '+=' + frameCount * 60,
      fastScrollEnd: true,
      preventOverlaps: true,
      anticipatePin: 2
    }
  })
    /* .to(text, {
       opacity: 1,
       x: -100,
       duration: 0.5
     })
         .to(text, {
       opacity: 0,
       x: 200,
       duration: 0.5
     })*/
    .to(airpods, {
      frame: frameCount - 1,
      snap: "frame",
      ease: "none",
      duration: 1
    }, 0);


  let tweenAnimation;

  //check on each uppdate if there is text to show per exact frame in current canvas
  function textSequenceAnimation() {
    let texts = section.querySelectorAll('.scroller-text');

    texts.forEach(setTextsTiming);

    //check all texts in current canvas
    function setTextsTiming(text, item) {

      let showingAtFrame = parseInt(text.getAttribute('data-frame'));
      let duration = text.getAttribute('data-duration') > 0 ? parseInt(text.getAttribute('data-duration')) : 50;
      let tween = text.getAttribute('data-tween') ? fromStringToJson(text.getAttribute('data-tween')) : {y: 0, opacity: 1};
      console.log(tween)

      function textAnimation(textItem, textFrame, textFrameDuration, tween) {
        if (airpods.frame <= textFrame) {

          //Fadeout
          tweenAnimation = gsap.to(textItem, {
            y: 40,
            opacity: 0,
            duration: .75,
            zIndex: 1
          })

        } else if (airpods.frame >= textFrame
          && airpods.frame < textFrame + textFrameDuration) {

          //Fadein
          tweenAnimation = gsap.to(textItem, tween)
        }

        if (airpods.frame >= textFrame + textFrameDuration) {
          //FadeoutUp
          tweenAnimation = gsap.to(textItem, {
            y: -40,
            opacity: 0,
            zIndex: 1
          })
        }
      }

      textAnimation(text.querySelector('.content-wrapper'), showingAtFrame, duration, tween);

    }
  }

  images[0].onload = render;

  function preloadFrames() {


    for (let i = 1; i <= frameCount; i++) {
      // if (init) {
      const img = new Image();
      img.src = currentFrame(i);
      images.push(img);
      if (img.complete) {
        incrementCounter();
      } else {
        img.addEventListener('load', incrementCounter, false);
      }
      //} else {
      //  break;
      //}
    }
  }

  function incrementCounter() {
    imgCounter++;
    // TODO: Loader to manage
    if (imgCounter === frameCount) {
      let loader = document.getElementById('canvas-loader');
      let body = document.getElementsByTagName('body')[0];
      loader.classList.add('hide');
      body.classList.remove('noscroll');
    }

    //consider do we need loader - remove it or show it
    //   document.getElementById('canvas-loader').classList.add('hide');
  }

  function render() {
    //  context.clearRect(0, 0, canvas.width, canvas.height);
    //  context.drawImage(images[airpods.frame], 0, 0);

    canvas.width = document.body.clientWidth; //user document.body.clientWidth to get the window viewport without the vertical scrollbar
    canvas.height = canvas.width;
    context.save();
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(images[airpods.frame], 0, 0, canvas.width, images[airpods.frame].height * (canvas.width / images[airpods.frame].width));
    context.restore();

    textSequenceAnimation();
  }
}

function initOther(section) {
  ScrollTrigger.create({
    trigger: section,
    pin: true,
    end: "+=200%"
  });
}

